const XLMEDIA = window.matchMedia('(min-width: 1200px)');
const DESKTOPMEDIA = window.matchMedia('(min-width: 992px)');
const TABLETMEDIA = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
const MOBILMEDIA = window.matchMedia('(max-width: 991px)');
const PHONEMEDIA = window.matchMedia('(max-width: 767px)');

export {
  DESKTOPMEDIA,
  MOBILMEDIA,
  TABLETMEDIA,
  PHONEMEDIA,
  XLMEDIA,
};
