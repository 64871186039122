import { $, $$ } from '../helper/utils';

const langSelect = $$('.lang-switch');
const countrySwitchInfo = $('.change-country-version');
const countrySwitchInfoClose = $('.change-country-version .close');
const langWrapper = $('.language-wrapper');

if (langWrapper !== null) {
  const langClose = langWrapper.querySelector('.close-menu');
  const timeline = gsap.timeline({
    paused: true,
    onComplete() {
      gsap.set(langWrapper, { clearProps: 'overflow,height' });
      document.body.classList.add('nav-open');
      window.scrollTo(0,0);
    },
    onReverseComplete() {
      if ($('.nav-open') !== null) {
        document.documentElement.classList.remove('noscroll');
      }

      langWrapper.setAttribute('aria-hidden', true);
    },
  });

  timeline
    .set(langWrapper, { overflow: 'hidden', display: 'block', visibility: 'inherit' })
    .from(langWrapper, {
      duration: 0.325,
      height: 0,
      ease: 'sine.out',
    })
    .to('.backdrop', {
      duration: 0.275,
      autoAlpha: 0.27,
      ease: 'sine.out',
    }, '<');

  $('.row:not(.header-overlay) .lang-switch').addEventListener('click', () => {
    document.documentElement.classList.add('noscroll');
    langWrapper.setAttribute('aria-hidden', false);

    requestAnimationFrame(() => {
      timeline.play();
    });
  });

  $('.overlay-sidebar-mobile-metanav .lang-switch').addEventListener('click', () => {
    langWrapper.setAttribute('aria-hidden', false);

    requestAnimationFrame(() => {
      timeline.play();
    });
  });

  if (langClose !== null) {
    langClose.addEventListener('click', () => {
      gsap.set(langWrapper, { overflow: 'hidden' });
      timeline.reverse();
    });
  }
}

export default function langSwitch() {
  if (countrySwitchInfoClose !== null) {
    countrySwitchInfoClose.addEventListener('click', () => {
      gsap.to(countrySwitchInfo, {
        duration: 0.225,
        height: 0,
        display: 'none',
        ease: 'sine.in',
        onComplete() {
          countrySwitchInfo.setAttribute('aria-hidden', true);
        },
      });
    }, { passive: true });
  }
}
