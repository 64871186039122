import { $, $$, $id, addEventListenerAll, debounce, delegate, throttle, offset } from '../helper/utils';
import { PHONEMEDIA } from '../helper/constants';
// import focusLoop from '../helper/focusLoop';

const isMobile = document.documentElement.classList.contains('is-mobile');
const showMenuBtn = $$('.show-menu');
const headerShowMenuBtn = $('#header .row:not(.header-overlay) .show-menu');
const nav = $('.desktop-nav');
const mainNav = $('.main-nav');
const subnavBg = $('.subnav-bg');
const subnavBack = $('.nav-back');
const navWrapper = $('.nav-wrapper');
const subnavWrapper = $('.sub-nav-wrapper');
const activeSubnav = $('.active-subnav');
const navBar1 = $$('.show-menu .bar1');
const navBar2 = $$('.show-menu .bar2');
const navBar3 = $$('.show-menu .bar3');
const state = {
  navOpen: false,
  subnavVisible: false,
  activeLink: null,
  activeSubnav: null,
  activeSubLink: null,
  activeSubSubnav: null,
};

const header = $('#header');
const headerHeight = header !== null ? header.offsetHeight : 0;
const treshold = headerHeight * 3;
let lastScroll = 0;
let scrollHeader = false;
let timeout = null;
let navOpen = false;
let scrollTop;

const hideHeader = (top) => {
  if ((navOpen === true && top > headerHeight && scrollHeader === true) || scrollHeader === false) {
    return;
  }

  scrollHeader = false;
  clearTimeout(timeout);
  timeout = null;
  requestAnimationFrame(() => {
    gsap.set(document.body, { clearProps: 'paddingTop' });
    header.classList.remove('header-nav');
  });
};

const scrollCheck = throttle(() => {
  const top = window.pageYOffset || document.documentElement.scrollTop;

  if (top < treshold) {
    if (top <= headerHeight || (top > lastScroll && scrollHeader === true)) {
      hideHeader(top);
    }

    lastScroll = top;
    return;
  }

  // scroll up show
  if (top < (lastScroll - 20) && scrollHeader === false) {
    scrollHeader = true;
    timeout = setTimeout(() => {
      requestAnimationFrame(() => {
        gsap.set(document.body, { paddingTop: header.offsetHeight });
        header.classList.add('header-nav');
      });
    }, 300);

    // scroll down hide
  } else if (top > (lastScroll + 20)) {
    hideHeader(top);
  }

  lastScroll = top;
}, 150);

export default function navigation() {
  if (showMenuBtn === null) {
    return;
  }

  // main timeline showing navigation
  const timeline = gsap.timeline({
    paused: true,
    onComplete() {
      // update local state
      state.navOpen = true;

      // make navigation visible to voice assistants
      nav.setAttribute('aria-hidden', false);

      // focus active nav item, or just mainnav if not a mobile device
      if (isMobile === false) {
        if (state.activeLink !== null) {
          state.activeLink.focus();
        } else {
          mainNav.focus();
        }
      }
    },
    onReverseComplete() {
      state.navOpen = false;
      nav.setAttribute('aria-hidden', true);
      document.body.classList.remove('nav-open');
    },
  });

  // add tweens
  timeline
    .to(navBar2, {
      duration: 0.1,
      scaleX: 0,
      ease: 'sine.in',
    })
    .to(navBar1, {
      duration: 0.1,
      y: 9,
      ease: 'sine.out',
    }, '<')
    .to(navBar3, {
      duration: 0.1,
      y: -9,
      ease: 'sine.out',
    }, '<')
    .to(navBar1, {
      duration: 0.125,
      rotation: 45,
      scaleX: 1,
      y: 11,
      ease: 'sine.out',
    })
    .to(navBar3, {
      duration: 0.125,
      rotation: -45,
      scaleX: 1,
      y: -13,
      ease: 'sine.out',
    }, '<');

  const subnavTl = gsap.timeline({
    paused: true,
    onStart() {
      nav.classList.add('subnav-open');
    },
    onReverseComplete() {
      state.activeLink.classList.remove('open');
      state.activeSubnav.hidden = true;
      state.activeSubnav.setAttribute('aria-hidden', true);
      state.activeSubnav = null;
      state.activeLink = null;
      state.subnavVisible = false;
      nav.classList.remove('subnav-open');
    },
  });

  subnavTl
    .set('.overlay-sidebar-mobile-metanav', {
      // duration: 0.125,
      autoAlpha: 0,
      // ease: 'sine.in',
    })
    .to(subnavBg, {
      duration: 0.375,
      scaleX: 1,
      ease: 'sine.out',
    })
    .set(subnavWrapper, { visibility: 'inherit' })
    .fromTo(subnavWrapper, {
      clipPath: 'inset(0 100% -10px 0)',
    }, {
      duration: 0.625,
      clipPath: 'inset(0 0% -10px 0)',
      ease: 'sine.inOut',
    })
    .to(subnavBack, {
      duration: 0.275,
      autoAlpha: 1,
      ease: 'sine.out',
    }, '<');

  const navTl = gsap.timeline({
    paused: true,
    onReverseComplete() {
      document.documentElement.classList.remove('noscroll');

      if (PHONEMEDIA.matches && state.activeSubSubnav !== null) {
        state.activeSubLink.classList.remove('open');
        state.activeSubSubnav.setAttribute('aria-hidden', true);
        activeSubnav.textContent = state.activeLink.textContent;

        gsap.set(state.activeSubSubnav.parentElement, {
          autoAlpha: 0,
          xPercent: 100,
        });

        gsap.set(state.activeSubSubnav, {
          display: 'none',
          opacity: 0,
        });

        state.activeSubLink = null;
        state.activeSubSubnav = null;

        subnavTl.reverse();
      } else {
        if (state.activeSubLink !== null) {
          state.activeSubLink.classList.remove('open');
          state.activeSubSubnav.setAttribute('aria-hidden', true);

          requestAnimationFrame(() => {
            gsap.set(state.activeSubSubnav, {
              display: 'none',
              opacity: 0,
            });

            state.activeSubLink = null;
            state.activeSubSubnav = null;
          });
        }

        subnavTl.reverse();
      }
    },
  });

  navTl
    .fromTo(nav, {
      scaleY: 0,
    }, {
      duration: 0.425,
      visibility: 'inherit',
      scaleY: 1,
      ease: 'sine.out',
    })
    .fromTo('.desktop-nav .show-menu', {
      autoAlpha: 0,
    }, {
      duration: 0.275,
      autoAlpha: 1,
      ease: 'sine.out',
    })
    .fromTo(navWrapper, {
      clipPath: 'inset(100% 0 0 0)',
    }, {
      duration: 0.525,
      clipPath: 'inset(0% 0 0 0)',
      ease: 'sine.out',
    }, '<');

  // open nav on menu button click
  addEventListenerAll(showMenuBtn, 'click', () => {
    // ignore click while tweening
    if (timeline.totalProgress() > 0 && timeline.totalProgress() < 1) {
      return;
    }

    // close nav if already open
    if (state.navOpen === true) {
      // reenable body scroll and set scroll position to last previous
      document.documentElement.classList.remove('noscroll');
      window.scrollTo(0, scrollTop);

      requestAnimationFrame(() => {
        // make reverse animations faster
        navTl.timeScale(1.25);
        navTl.reverse();
        timeline.timeScale(1.25);
        timeline.reverse();
      });

      return;
    }

    // get current vertical scroll position to revert to after closing nav (mostly for mobile)
    scrollTop = window.scrollY;

    // prevent body scroll
    document.documentElement.classList.add('noscroll');

    document.body.classList.add('nav-open');

    requestAnimationFrame(() => {
      // reset timeline speed to default and start
      timeline.timeScale(1);
      timeline.play();
      navTl.timeScale(1);
      navTl.play();
    });
  }, { passive: true });

  subnavBack.addEventListener('click', () => {
    if (PHONEMEDIA.matches && state.activeSubSubnav !== null) {
      state.activeSubLink.classList.remove('open');
      state.activeSubSubnav.setAttribute('aria-hidden', true);
      activeSubnav.textContent = state.activeLink.textContent;

      requestAnimationFrame(() => {
        gsap.to(state.activeSubSubnav.parentElement, {
          duration: 0.2,
          autoAlpha: 0,
          xPercent: 100,
          ease: 'sine.in',
        });

        gsap.to(state.activeSubSubnav, {
          duration: 0.2,
          display: 'none',
          opacity: 0,
          ease: 'sine.in',
        });

        state.activeSubLink = null;
        state.activeSubSubnav = null;
      });
    } else {
      subnavTl.reverse();
    }
  }, { passive: true });

  // handle the slide in/out of subnavs
  function handleSubnav(e) {
    if (e instanceof Event) {
      e.preventDefault();
    }

    const { subnav } = this.dataset;

    this.classList.add('open');

    if (this.parentElement.classList.contains('subnav-left')) {
      // hide open subnav
      if (state.activeSubLink === this) {
        state.activeSubLink.classList.remove('open');
        state.activeSubSubnav.setAttribute('aria-hidden', true);

        requestAnimationFrame(() => {
          gsap.to(state.activeSubSubnav, {
            duration: 0.2,
            display: 'none',
            opacity: 0,
            ease: 'sine.in',
          });

          state.activeSubLink = null;
          state.activeSubSubnav = null;
        });
      } else if (state.activeSubLink !== null) {
        // hide open subnav
        state.activeSubLink.classList.remove('open');
        state.activeSubSubnav.setAttribute('aria-hidden', true);
        gsap.set(state.activeSubSubnav, { display: 'none', opacity: 0 });

        // show new subnav
        state.activeSubLink = this;
        state.activeSubSubnav = $id(subnav);
        state.activeSubSubnav.setAttribute('aria-hidden', false);
        state.activeSubSubnav.classList.add('open');

        requestAnimationFrame(() => {
          gsap.to(state.activeSubSubnav, {
            duration: 0.225,
            display: 'block',
            opacity: 1,
            ease: 'sine.out',
          });
        });
      } else {
        state.activeSubLink = this;
        state.activeSubSubnav = $id(subnav);
        state.activeSubSubnav.setAttribute('aria-hidden', false);

        requestAnimationFrame(() => {
          // for mobile slide in subnav wrapper
          if (PHONEMEDIA.matches) {
            activeSubnav.textContent = state.activeSubLink.textContent;

            gsap.fromTo(state.activeSubSubnav.parentElement, {
              xPercent: 100,
            }, {
              duration: 0.225,
              autoAlpha: 1,
              xPercent: 0,
              ease: 'sine.out',
            });
          }

          gsap.to(state.activeSubSubnav, {
            duration: 0.225,
            display: 'block',
            opacity: 1,
            ease: 'sine.out',
          });
        });
      }
    } else {
      state.activeLink = this;
      state.subnavVisible = true;
      state.activeSubnav = $id(subnav);
      state.activeSubnav.setAttribute('aria-hidden', false);
      state.activeSubnav.hidden = false;

      requestAnimationFrame(() => {
        subnavTl.play();
      });
    }
  }

  delegate(navWrapper, 'click', '.nav-link[data-subnav]', handleSubnav);

  const resize = debounce(() => {
    // if mobile viewport and subsubnav is open show mobile only UI updates
    if (PHONEMEDIA.matches && state.activeSubSubnav !== null) {
      // update text
      activeSubnav.textContent = state.activeSubLink.textContent;

      // show subnav parent
      gsap.set(state.activeSubSubnav.parentElement, {
        autoAlpha: 1,
        xPercent: 0,
      });

      // if not mobile viewport unset mobile only UI updates
    } else if (PHONEMEDIA.matches === false) {
      // update text
      if (state.activeLink !== null) {
        activeSubnav.textContent = state.activeLink.textContent;
      }

      // clear subnav parent styles
      gsap.set('.subnav-right', { clearProps: 'all' });
    }


    // update body padding ig sticky header is visible
    if (header.classList.contains('header-nav')) {
      gsap.set(document.body, { paddingTop: header.offsetHeight });
    }
  }, 250);

  window.addEventListener('resize', resize);
  window.addEventListener('scroll', scrollCheck);
}
