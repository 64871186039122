import { $, $$, debounce, wrap, offset, addEventListenerAll, isDebug } from './helper/utils';
import outline from './helper/outline';
import inView from './helper/inView';
import navigation from './components/navigation';
import langSwitch from './components/langSwitch';
import { PHONEMEDIA } from './helper/constants';

const loaders = $$('.loader');
const sliderMap = new Map();
const sliderDragging = new Map();

for (const loader of loaders) {
  const loaderEl = document.createElement('span');
  loaderEl.className = 'loading-indicator';
  loader.appendChild(loaderEl);
  loader.classList.remove('loader');
}

outline();
navigation();
langSwitch();

if (document.body.classList.contains('home')) {
  let sideClip = PHONEMEDIA.matches ? 8 : 24;
  const headerRow = $('#header > .container > .row');
  const headerContentClone = headerRow.cloneNode(true);
  headerContentClone.classList.add('header-overlay');
  headerRow.parentElement.prepend(headerContentClone);

  setTimeout(() => {
    inView($$('.banner'), () => {
      console.log('view');
      const tl = gsap.timeline();
      tl
        .to(headerContentClone, {
          duration: 0.5,
          delay: 1,
          clipPath: 'inset(100% 0px 0px)',
          ease: 'sine.out',
        })
        .to('.banner', {
          duration: 0.5,
          clipPath: `inset(${headerRow.offsetHeight}px ${sideClip}px ${sideClip}px)`,
          ease: 'sine.out',
        }, '<')
        .set(headerContentClone, { display: 'none' });

      const resize = debounce(() => {
        sideClip = PHONEMEDIA.matches ? 8 : 24;
        gsap.to('.banner', {
          duration: 0.25,
          clipPath: `inset(${headerRow.offsetHeight}px ${sideClip}px ${sideClip}px)`,
          ease: 'sine.out',
        });
      }, 250);

      setTimeout(() => {
        window.addEventListener('resize', resize);
      }, 1000);
    }, true);
  }, 150);
}

// add a wrapper to reference tables
$$('.reference-table').forEach((table) => {
  if (table.parentElement.classList.contains('reference-table-wrapper')) {
    return;
  }

  const wrapper = document.createElement('div');
  wrapper.className = 'reference-table-wrapper';
  wrap(table, wrapper);
});

// add a scroll down button to banner element
if ($('.banner .full-size:not(.banner-cards)') !== null) {
  const text = window.translations['scroll-down'] ?? 'Scroll down';
  const bannerCol = $('.banner > .row > .col-12');
  const scrollDown = document.createElement('button');
  scrollDown.className = 'scroll-down';
  scrollDown.innerHTML = `${text} <i class="icon-chevron-down"></i>`;
  bannerCol.appendChild(scrollDown);

  scrollDown.addEventListener('click', () => {
    const bannerOffset = offset(bannerCol);
    window.scroll({ top: bannerOffset.bottom, left: 0, behavior: 'smooth' });
  }, { passive: true });
}

// zoom image on more btn hover in inset row elements
addEventListenerAll($$('.inset-row a.more'), 'mouseenter', function () {
  const insetRowImagebox = this.closest('.inset-row').querySelector('.imagebox');
  insetRowImagebox?.classList.add('hover');
});
addEventListenerAll($$('.inset-row a.more'), 'mouseleave', function () {
  const insetRowImagebox = this.closest('.inset-row').querySelector('.imagebox');
  insetRowImagebox?.classList.remove('hover');
});

export default function () {
  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.form-group > .form-line') !== null) {
    import('./components/forms/form')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk forms/form.js loading failed', err);
      });
  }

  if ($('.login-overlay') !== null) {
    import('./components/forms/login')
      .catch((err) => {
        console.warn('Chunk forms/login.js loading failed', err);
      });
  }

  if ($('.search-wrapper') !== null) {
    import('./components/forms/search')
      .catch((err) => {
        console.warn('Chunk forms/search.js loading failed', err);
      });
  }

  // if ($('.scroll-headline-target') !== null) {
  //   import('./components/scrollHeadline')
  //     .catch((err) => {
  //       console.warn('Chunk scrollHeadline.js loading failed', err);
  //     });
  // }

  if ($('.scroll-headline-target') !== null) {
    import('./components/textReveal')
      .then((textReveal) => textReveal.default())
      .catch((err) => {
        console.warn(`Chunk textReveal.js loading failed: ${err}`);
      });
  }

  if ($('.filter-wrapper') !== null) {
    import('./components/forms/elementFilter')
      .catch((err) => {
        console.warn('Chunk forms/elementFilter.js loading failed', err);
      });
  }

  if ($('.banner-image') !== null && $('.home .banner') === null) {
    Promise.all([import('../libs/gsap/ScrollTrigger'), import('../libs/gsap/EasePack')])
      .then((values) => {
        const debug = {};

        if (isDebug(true)) {
          debug.markers = true;
        }

        gsap.registerPlugin(values[0].ScrollTrigger, values[1].ExpoScaleEase);
        gsap.to('.banner-image img', {
          duration: 1,
          scale: 1.15,
          ease: 'expoScale(1, 1.15)',
          scrollTrigger: {
            ...debug,
            id: 'scroll-zoom',
            scrub: 0.2,
            start: 'top top',
            end: () => $('#header').offsetHeight + $('.banner').offsetHeight,
          },
        });
      })
      .catch((err) => {
        console.warn('Chunk ScrollTrigger.js and/or EasePack.js loading failed', err);
      });
  }

  setTimeout(() => {
    if ($('.banner-battery-anim') !== null) {
      inView($$('.banner'), () => {
        const apng = $('.battery-img-link').href;
        const targetImg = $('.banner-battery-anim');
        const temp = new Image();
        temp.src = apng;
        temp.onload = () => {
          targetImg.src = temp.src;
          gsap.to('.banner-battery-shadow', {
            duration: 2.36,
            delay: 0.1,
            opacity: 1,
            scaleY: 1,
            ease: 'sine.out',
          });
        };
      }, true);
    }
  }, 300);

  // inView($$('.lightbox'), () => {
  //   import('./components/lightbox')
  //     .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // }, true);

  // if ($('.modal-wrapper') !== null) {
  //   import('./components/modal')
  //     .catch((err) => {
  //       console.warn('Chunk modal.js loading failed', err);
  //     });
  // }

  if ($$('.nav-tabs, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });
    };

    const collapseMap = new Map();
    const showsMore = new Set();
    const toggleText = function () {
      if (showsMore.has(this)) {
        this.textContent = collapseMap.get(this).more;
        showsMore.delete(this);
      } else {
        this.textContent = collapseMap.get(this).less;
        showsMore.add(this);
      }
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }

        // toggle show more button text
        const showHide = $$('[data-showless]');
        for (let i = 0; i < showHide.length; i++) {
          const el = showHide[i];

          collapseMap.set(el, {
            more: el.textContent,
            less: el.dataset.showless,
          });
          el.addEventListener('click', toggleText);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
